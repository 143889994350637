<template>
  <layout class="main-cols">
    <div
      class="columns is-multiline h-full items-center flex-row justify-center h-85vh"
    >
      <div class="column is-6">
        <b-image class="is-6" src="/images/error500.png" alt="" />
      </div>
      <div class="column is-12 mt-8">
        <p class="has-text-danger text-4xl font-bold">Error del servidor</p>
        <p>
          Estamos trabajando todo lo posible para arreglar este error.
        </p>
        <p>Intente de nuevo más tarde.</p>
      </div>
      <div class="column is-4">
        <b-button type="is-primary" expanded @click="$router.back(2)"
          >Regresar</b-button
        >
      </div>
    </div>
  </layout>
</template>

<script>
export default {
  name: "Error500"
};
</script>
